<template>
  <div>
     <van-cell><span style="color: #57c4b7;">*温馨提示：如之前注册过就诊卡，可直接前往升级为电子健康卡</span></van-cell>
     <div style="padding:15px;">
       <div style="padding:5px;border-radius:5px;margin-top:5px;box-shadow: 0 0 6px rgb(207 216 221 / 63%), 0 4px 6px rgb(207 216 221 / 63%);">
            <div @click="upgrade" style="padding-left:60px;font-size:16px;border-radius:5px;height:60px;line-height:60px;padding:10px;background:linear-gradient(to right , rgb(87, 196, 183),rgba(87, 196, 183,0.6) );color:#fff" >
                <span style="float:left;padding-left:20px">院内就诊卡升级为健康卡</span>          
            </div>
        </div>

        <div style="padding:5px;border-radius:5px;margin-top:5px;box-shadow: 0 0 6px rgb(207 216 221 / 63%), 0 4px 6px rgb(207 216 221 / 63%);">
            <div @click="binding" style="padding-left:60px;font-size:16px;border-radius:5px;height:60px;line-height:60px;padding:10px;background:linear-gradient(to right , rgb(87, 196, 183),rgba(87, 196, 183,0.6) );color:#fff" >
                <span style="float:left;padding-left:20px">已有健康卡，前往一键绑定</span>          
            </div>
        </div>

        <div style="padding:5px;border-radius:5px;margin-top:5px;box-shadow: 0 0 6px rgb(207 216 221 / 63%), 0 4px 6px rgb(207 216 221 / 63%);">
            <div @click="newInput" style="padding-left:60px;font-size:16px;border-radius:5px;height:60px;line-height:60px;padding:10px;background:linear-gradient(to right , rgb(87, 196, 183),rgba(87, 196, 183,0.6) );color:#fff" >
                <span style="float:left;padding-left:20px">注册绑定新的健康卡</span>          
            </div>
        </div>

     </div>
     <van-popup :close-on-click-overlay="false"  closeable v-model="show" :style="{height:'200px',width:'300px',padding:'10px'}">
               <div style="text-align:center;margin-top:5px;font-weight:bold;">一键绑定信息补全</div>
             <div style="margin-top:50px;">
               <span>家庭地址：</span> <input placeholder="请输入家庭地址" type="text" v-model="address" style="height:30px" >
             </div>
          
            <van-button style="display:block;margin:30px auto;"  color="#57c4b7" type="primary" @click="bind">继续绑定</van-button>
          
     </van-popup>
    <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>@拓实银医</span></div>
  </div>
</template>

<script>
// import { Toast } from 'vant';
import ajax from '../../lib/ajax';
import store from '../../store';
import {  Dialog,Toast } from "vant";
const hospitalld="34742";
export default {
  data(){
    return{
      openid:"",
      HealthCode:"",
      show:false,
      address:""
    }
  },
 mounted(){
    
   this.HealthCode = this.$route.query.healthCode;
   if(this.HealthCode){
        if(this.HealthCode==0){
                    this.$router.replace("/HcNewI");
                  }else if(this.HealthCode==-1){
                     
                  wx.closeWindow();
                  }else{
                    this.inputaddress();
                  }
   }
   if(!this.HealthCode){
     this.onload()
   }
   this.openid =  sessionStorage.getItem('openid'); 

 },
  methods:{
    onload(){
      // let openid = store.getters["wechat/openid"];
      // let openid = store.getters["wechat/openid"];
      // console.log(openid)
       ajax.post("/Api/CardService/WeChatGetOwnerMembers?input=" + this.openid)
        .then((res) => {
          console.log(res)
           res.data.result.forEach(el => {
                 if(!el.barCode){
                  Dialog.confirm({
                        title: '温馨提示',
                        message: '您有还未升级为地址健康卡的就诊卡成员，是否立即前往升级?',
                      })
                        .then(() => {
                          this.upgrade();
                        })
                        .catch(() => {
                          
                        });
                }
            });
        })
    },
    // 用户升级
    upgrade(){
      console.log("升级")
      this.$router
        .replace({
          path: "/ulist"
        })
        .catch(err => {
          console.log(err);
        });
    },
    //一键绑定按钮
    binding(){
     let redirect_uri="http://wx.fgxrmyy.cn/healcardM";
     window.location.href=`https://health.tengmed.com/open/getHealthCardList?redirect_uri=${redirect_uri}&hospitalId=${hospitalld}`;
    },
    inputaddress(){
      this.show=true;
    },
    // 
    bind(){                
                          if(this.address==""){
                            Toast.fail("家庭地址不能为空！");
                            return;
                          }
           ajax.post("/Api/HealthCardService/GetHealthCardByHealthCode",{healthCode:this.HealthCode})
      .then((res)=>{
        // console.log(res)
         Toast.fail( res.data.result.commonOut.errMsg)
       
        let card=res.data.result.rsp.card;
        console.log("卡")
        console.log(card)
         let c = card.idCard;
              let year = c.substring(6, 10);
              let month = c.substring(10, 12);
              let day = c.substring(12, 14);
              let Birthday = year + "-" + month + "-" + day;
              let ownerIDCard =store.getters["store/owneridcard"];
               ajax.get(`/Api/HealthCardService/BindCardRelation?QrCodeText=${card.qrCodeText}&Patid=${card.idCard}`)
      .then((res)=>{
        console.log(res)
        console.log("开始绑定了");
        if(res.data.result.commonOut.errMsg=="成功"){
                          let sex =card.gender
            
                    ajax.post("/Api/CardService/WeChatRegistOther", {
                      openID:this.openid,
                      barCode:card.healthCardId,
                      phone:card.phone1,
                      sex:sex,
                      birthday:Birthday,
                      userName: card.name,
                      idCard:card.idCard,
                      familyAddress:this.address,
                      ownerIDCard:ownerIDCard,
                      machineName:"wechat",
                      operatorId:'0733'
            }).then((res)=>{
              console.log(res);
               Toast.fail("绑定成功");
              this.$router.replace("/healcard").catch((err)=>{
                console.log(err)
              })
            })
        }else{
          Toast.fail(res.data.result.commonOut.errMsg);
          return;
        }
      })
                //  console.log(subData);
                //  console.log(this.appToken)
             
        
      })
      .catch((err)=>{console.log(err)
      this.show=false;
      Toast.fail('HealthCode获取失败');})
    },
    newInput(){
      console.log("新注册")
    let redirect_uri="http://wx.fgxrmyy.cn/HcNewI";
     window.location.href=`https://health.tengmed.com/open/getUserCode?apiVersion=2&redirect_uri=${redirect_uri}`;
    }
  }
}
</script>

